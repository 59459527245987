


import FETCHING from '../../library/fetching'
import DATA_MASTER from '../../library/dataMaster'

export default {
  data() {
    return {

      form : {
        id : '',
        nik : '',
        nama_lengkap : '',
        tmp_lahir : '',
        tgl_lahir : '',
        jns_kelamin : '',
        alamat : '',
        dusun : '',
        rt_rw : '',
        kecamatan_id : '',
        // des_kel_id : '',
        telp_hp : '',
        email : '',
        kode_pos : '',
        status_perkawinan_id : '',
        uraian_perkawinan : '',
        agama_id : '',
        uraian_agama : '',
        tinggi_bdn : '',
        berat_bdn : '',
        file : null,
        file_type : '',
      },

      form1 : {
        id : '',
        biodata_id : '',
        uraian : '',
        file : null,
        file_type : ''
      },

      // ====================================== CONTOH AUTOCOMPLETE ====================================
      autocomplete_db : '',
      listKecamatan : '',
      listDesKel : '',
      // ====================================== CONTOH AUTOCOMPLETE ====================================
      


     
      list_data : [],
      list_lampiran : [],
      list_bahasa : [],

      page_first: 1,
      page_last: 0,
      page_limit: 10,
      cari_value : "",
      page_first1 : 1,
      page_last1 : 0,
      page_limit1 : 8,
      file_old : "",
      cek_load_data : true,
      file_path: this.$store.state.url.URL_APP + "uploads/",

      mdl_add: false,
      mdl_edit: false,
      mdl_hapus : false,
      mdl_lihat : false,
      mdl_lampiran : false,
      mdl_lampiran_add : false,
      mdl_lihat_lampiran : false,
      mdl_bahasa : false,
      mdl_bahasa_add : false,
      btn_add: false,


      FETCHING : FETCHING,
      DATA_MASTER : DATA_MASTER,
    }
  },
  methods: {
    getView : function(){
      this.$store.commit("shoWLoading");
      fetch(this.$store.state.url.URL_BIO_KETERANGAN_UMUM + "view", {
          method: "POST",
          headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
              data_ke: this.page_first,
              cari_value: this.cari_value,
              // kecamatan_id : this.form.kecamatan_id,
          })
      })
          .then(res => res.json())
          .then(res_data => {
              this.list_data = res_data.data;
              this.page_last = res_data.jml_data;
              this.$store.commit("hideLoading");
              // console.log(res_data);
      });
    },

    selectData : function(data){
        this.form.id = data.id;
        this.form.nik = data.nik;
        // this.form.nama_lengkap = data.nama_lengkap;
        this.form.tmp_lahir = data.tmp_lahir;
        this.form.tgl_lahir = data.tgl_lahir;
        this.form.jns_kelamin = data.jns_kelamin;
        this.form.alamat = data.alamat;
        this.form.dusun = data.dusun;
        this.form.rt_rw = data.rt_rw;
        this.form.kecamatan_id = data.kecamatan_id;
        this.form.telp_hp = data.telp_hp;
        this.form.email = data.email;
        this.form.kode_pos = data.kode_pos;
        this.form.status_perkawinan_id = data.status_perkawinan_id;
        this.form.uraian_perkawinan = data.uraian_perkawinan;
        this.form.agama_id = data.agama_id;
        this.form.uraian_agama = data.uraian_agama;
        this.form.tinggi_bdn = data.tinggi_bdn;
        this.form.berat_bdn = data.berat_bdn;
        this.form.file = data.file;
        this.file_old = data.file;

        this.form.username = data.username;
        this.form.nama = data.nama;
        this.form.email = data.email;
    },

    getView1 : function(idku){
      // console.log(idku)
      this.form1.biodata_id = idku
      this.$store.commit("shoWLoading");
      fetch(this.$store.state.url.URL_BIO_LAMPIRAN + "view", {
          method: "POST",
          headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
            biodata_id : this.form1.biodata_id,
            data_ke : this.page_first1,
            cari_value : this.cari_value1,
            page_limit : this.page_limit1,
          })
      })
          .then(res => res.json())
          .then(res_data => {
            this.list_lampiran = res_data.data;
            this.page_last = res_data.jml_data;
            this.$store.commit("hideLoading");
            // console.log(res_data);
      });
    },

    addData1 : function() {
      var formData = new FormData();
      formData.append("biodata_id", this.form1.biodata_id);
      formData.append("uraian", this.form1.uraian);
      formData.append("file", this.form1.file);
      formData.append("file_type", this.form1.file_type);

      fetch(this.$store.state.url.URL_BIO_LAMPIRAN + "addData", {
          method: "POST",
          headers: {
            // "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: formData
      }).then(res_data => {
          this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
          this.getView1(this.form1.biodata_id);
      });
    },

    removeData1 : function(idnya, file){
      fetch(this.$store.state.url.URL_BIO_LAMPIRAN + "removeData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
            id : idnya,
            file : file, 
          })
      }).then(res_data => {
          this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
          this.getView1(this.form1.biodata_id);
      });

    },

    selectData1 : function(data){
        this.form1.id = data.id;
        this.form1.biodata_id = data.biodata_id;
        this.form1.uraian = data.uraian;;
        this.form1.file = data.file;
        this.form1.file_type = data.file_type;
        this.file_old = data.file;
    },

    // ====================================== PAGINATE ====================================
    Notify : function(message, positive, icon){
        this.$q.notify({
        message: message,
        color: positive,
        icon: icon,
        position : 'top',
        timeout: 500,
        })
    },
    btn_prev : function(){
        this.cek_load_data = true;
        if(this.page_first>1){
            this.page_first--
        }else{
            this.page_first = 1;
        }
        this.getView();
    },

    btn_next : function(){
        if(this.page_first >= this.page_last){
            this.page_first == this.page_last
        }else{
            this.page_first++;
        }
        this.getView();
    },
    indexing : function(index){
        var idx = ((this.page_first-1)*this.page_limit)+index
        return idx;
    },
    cari_data : function(){
        this.page_first = 1;
        this.getView();
    },


    // ====================================== PAGINATE ====================================

  },

  mounted () {
    // FETCHING.getKecamatan('');
    // FETCHING.getDesKel(this.form.kecamatan_id);
    DATA_MASTER.getAgama();
    DATA_MASTER.getStatusPerkawinan();
    DATA_MASTER.getKecamatan();
    // DATA_MASTER.getDesKel();
    this.getView();
    this.getView1();
  },
}
